//
// Basic Bootstrap table
//

.table {
  --#{$prefix}table-color: #{$table-color};
  --#{$prefix}table-bg: #{$table-bg};
  --#{$prefix}table-border-color: #{$table-border-color};
  --#{$prefix}table-accent-bg: #{$table-accent-bg};
  --#{$prefix}table-striped-color: #{$table-striped-color};
  --#{$prefix}table-striped-bg: #{$table-striped-bg};
  --#{$prefix}table-active-color: #{$table-active-color};
  --#{$prefix}table-active-bg: #{$table-active-bg};
  --#{$prefix}table-hover-color: #{$table-hover-color};
  --#{$prefix}table-hover-bg: #{$table-hover-bg};

  width: 100%;
  margin-bottom: $spacer;
  color: var(--#{$prefix}table-color);
  vertical-align: $table-cell-vertical-align;
  border-color: var(--#{$prefix}table-border-color);

  // Target th & td
  // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
  // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
  // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: $table-cell-padding-y $table-cell-padding-x;
    background-color: var(--#{$prefix}table-bg);
    border-bottom-width: $table-border-width;
    box-shadow: inset 0 0 0 9999px var(--#{$prefix}table-accent-bg);
  }

  > tbody {
    vertical-align: inherit;
  }

  > thead {
    vertical-align: bottom;
    // background-color: #ffc46f;
    background-color: #7c9edd;
    color: $white;
    text-transform: capitalize;
  }
}

.table-group-divider {
  border-top: calc(#{$table-border-width} * 2) solid
    $table-group-separator-color; // stylelint-disable-line function-disallowed-list
}

//
// Change placement of captions with a class
//

.caption-top {
  caption-side: top;
}

//
// Condensed table w/ half padding
//

.table-sm {
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
  }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.
//
// When borders are added on all sides of the cells, the corners can render odd when
// these borders do not have the same color or if they are semi-transparent.
// Therefor we add top and border bottoms to the `tr`s and left and right borders
// to the `td`s or `th`s

.table-bordered {
  > :not(caption) > * {
    border-width: $table-border-width 0;

    // stylelint-disable-next-line selector-max-universal
    > * {
      border-width: 0 $table-border-width;
    }
  }
}

.table-borderless {
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    border-bottom-width: 0;
  }

  > :not(:first-child) {
    border-top-width: 0;
  }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

// For rows
.table-striped {
  > tbody > tr:nth-of-type(#{$table-striped-order}) > * {
    --#{$prefix}table-accent-bg: var(--#{$prefix}table-striped-bg);
    color: var(--#{$prefix}table-striped-color);
  }
}

// For columns
.table-striped-columns {
  > :not(caption) > tr > :nth-child(#{$table-striped-columns-order}) {
    --#{$prefix}table-accent-bg: var(--#{$prefix}table-striped-bg);
    color: var(--#{$prefix}table-striped-color);
  }
}

// Active table
//
// The `.table-active` class can be added to highlight rows or cells

.table-active {
  --#{$prefix}table-accent-bg: var(--#{$prefix}table-active-bg);
  color: var(--#{$prefix}table-active-color);
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover > * {
    --#{$prefix}table-accent-bg: var(--#{$prefix}table-hover-bg);
    color: var(--#{$prefix}table-hover-color);
  }
}

// Table variants
//
// Table variants set the table cell backgrounds, border colors
// and the colors of the striped, hovered & active tables

@each $color, $value in $table-variants {
  @include table-variant($color, $value);
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-down($breakpoint) {
    .table-responsive#{$infix} {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.table-head {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    .search-box {
      position: relative;
      color: $primary;
      background-color: unset;
      .search-btn {
        position: absolute;
        right: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        box-shadow: none;
        background-color: unset;
        padding: 0;
        color: $primary;
      }
      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
      .form-control {
        padding-right: 2.1rem;
      }
    }
  }
}
.table {
  thead {
    tr {
      border: none;
      th {
        border: none;
        font-size: 1rem;
        position: relative;
        padding: 0.7rem 0.6rem;
        &:first-child {
          border-top-left-radius: 0.4rem;
        }
        &:last-child {
          border-top-right-radius: 0.4rem;
        }
        span {
          // position: absolute;
          // right: 0.5rem;
          // top: 53%;
          // transform: translateY(-50%);
          width: 0.5rem;
          display: inline-block;
          margin-left: 0.6rem;
          &::before {
            content: "" !important;
            display: block;
            width: 100%;
            height: 100%;
            border-bottom: 0.26rem solid currentcolor;
            border-right: 0.26rem solid transparent;
            border-top: 0;
            border-left: 0.26rem solid transparent;
            margin-bottom: 0.2rem;
            margin-left: 0 !important;
          }
          &::after {
            content: "" !important;
            display: block;
            width: 100%;
            height: 100%;
            border-top: 0.26rem solid currentcolor;
            border-right: 0.26rem solid transparent;
            border-bottom: 0;
            border-left: 0.26rem solid transparent;
          }
        }
        &.action-box {
          width: 9%;
          &.w-15 {
            width: 15%;
          }
        }
        &.date-box {
          width: 12%;
        }
        &.status-box {
          width: 11%;
        }
        &.table-head-img-box {
          width: 7%;
        }
        &.date-time-box {
          width: 20%;
        }
      }
    }
  }
  tbody {
    border: 1px solid $gray-400;
    border-top: none;
    border-radius: 0 0 0.4rem 0.4rem;
    tr {
      border: none;
      transition: all 0.3s ease;
      &:hover {
        > * {
          --bs-table-accent-bg: rgb(210 226 255) !important;
        }
        .add-family-row {
          --bs-table-accent-bg: rgb(255 232 200) !important;
        }
      }
      &:nth-of-type(even) {
        > * {
          --bs-table-accent-bg: rgb(232 240 255);
        }
      }
      &:nth-of-type(odd) {
        > * {
          --bs-table-accent-bg: #fff;
        }
      }
      td {
        font-size: 0.9rem;
        line-height: normal;
        padding: 0.4rem 0.5rem;
        vertical-align: middle;
        color: $black;
        border: none;
        p {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          line-height: 1.4;
          overflow: hidden;
          white-space: pre-wrap;
        }
        .badge {
          background-color: $primary;
          width: 4.7rem;
          font-size: 0.8rem;
          &.badge-danger {
            background-color: #c55147;
          }
          &.badge-success {
            background-color: #14c970;
          }
        }
        .btn {
          &.text-success {
            &:is(:hover, :focus) {
              color: $primary !important;
            }
          }
          &.btn-success {
            &:is(:hover, :focus) {
              color: $white !important;
            }
          }
        }
        &.add-family-box {
          div {
            display: flex;
            align-items: center;
            justify-content: end;
            gap: 0.5rem;
            .btn {
              padding: 0.2rem 0.4rem;
              line-height: 1.4;
              white-space: pre;
              &.btn-danger {
                display: none;
                background-color: #c55147 !important;
              }
            }
          }
        }
        &.add-family-row {
          --bs-table-accent-bg: rgb(255 232 200);
          .btn-danger {
            display: block;
          }
          .btn-success {
            display: none;
          }
        }
      }
    }
  }
  &.theme-table {
    td {
      vertical-align: top;
    }
  }
  &.reportstory-table {
    thead {
      tr {
        th {
          &:nth-child(2) {
            padding-left: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:nth-child(2) {
            padding-left: 0;
          }
        }
      }
    }
    .status-box {
      .btn-success {
        background-color: darken($secondary, 7%);
        color: $white;
      }
    }       
  }
}
.table-img-box {
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 5rem;
  img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.3rem;
    // aspect-ratio: 4.5/2;
  }
  &.sm {
    height: 2.9rem;
  }
}
.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .record-perpage {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    .dropdown-toggle {
      background: transparent;
      color: #212121;
      border-radius: 5px;
      padding: 0.2rem 0.1rem;
      display: block;
      font-size: 0.9rem;
      min-width: 3rem;
      border: 1px solid #b2b6bb;
    }
    .dropdown-menu {
      min-width: 100%;
      padding: 0;
      .dropdown-item {
        font-size: 0.8rem;
        padding: 0.4rem 0.4rem;
        &:hover {
          background-color: #dedede;
        }
      }
    }
    .totle_records {
      font-size: 0.94rem;
      color: #717171;
    }
  }
}

.rdt_Table {
  border: 1px solid $gray-400;
  border-radius: 0.3rem;
  overflow: hidden;
  .rdt_TableHeadRow {
    min-height: 2.5rem;
    background-color: #7c9edd;
    color: $white;
    border: none;
    span {
      width: 0.5rem;
      font-size: 0;
      margin-top: 0.3rem;
      margin-left: 0.3rem;
      transform: none;
      &::before {
        content: "" !important;
        display: block;
        width: 100%;
        height: 100%;
        border-bottom: 0.26rem solid currentcolor;
        border-right: 0.26rem solid transparent;
        border-top: 0;
        border-left: 0.26rem solid transparent;
        margin-bottom: 0.2rem;
        margin-left: 0 !important;
      }
      &::after {
        content: "" !important;
        display: block;
        width: 100%;
        height: 100%;
        border-top: 0.26rem solid currentcolor;
        border-right: 0.26rem solid transparent;
        border-bottom: 0;
        border-left: 0.26rem solid transparent;
      }
    }
    .rdt_TableCol {
      font-size: 0.94rem;
      padding: 0 0.6rem;
      font-weight: 600;
    }
    .rdt_TableCol_Sortable {
      &:hover {
        opacity: 1;
        span {
          opacity: 0.7;
        }
      }
    }
  }
  .rdt_TableBody {
    .rdt_TableRow {
      border: none;
      min-height: 2.3rem;
      transition: all 0.2s ease;
      .rdt_TableCell {
        font-size: 0.84rem;
        padding: 0 0.6rem;
        // border: 1px solid #dadada;
      }
      &:nth-child(even) {
        background-color: rgb(232 240 255);
      }
      &:hover {
        background-color: rgb(221 232 255);
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .table {
    th {
      &.table-head-img-box {
        width: 9% !important;
      }
    }
  }
}
@media screen and (max-width: 1439px) {
  .table {
    th {
      &.date-box {
        width: 28% !important;
      }
      &.table-head-img-box {
        width: 10% !important;
      }
      &.story-title-box {
        width: 40%;
      }
    }
  }
}
