@import './style.scss';

// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');


html {
    scroll-behavior: smooth;
}
body {
    overflow-x: hidden;
}
  
.front-wrapper {
    font-family: 'Nunito', sans-serif;

.pre_loader {
    position: fixed;
    z-index: 99999;
}

img {
    max-width: min-content;
}
p {
    font-size: 1.1875rem;
    color: $gray-700;
    line-height: 1.875rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
    letter-spacing: 0.06rem;
    &:last-of-type {
        margin-bottom: 0;}
}
h4 {
    font-weight: 600;
}
h3, h5 {
    font-weight: 700;            
}
section {
    padding: 5rem 0;
}
&.header-fix {
    header {
        box-shadow: -1px 2px 7px 0px $gray-200;
    }
}
.animated {
    z-index: 9;
    position: relative;
}
.back-to-top {
    height: 0;
    width: 3rem;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center; 
    position: fixed;
    bottom: 2rem;
    right: 1.5rem;
    border-radius: 50%;
    border: none;    
    z-index: 9999;    
    font-size: 1rem;
    opacity: 0;
    cursor: context-menu !important;
    color: $white;    
    transition: height .2s ease;
    &:hover, &:focus {
        background-color: lighten($front-light-pink, 20%);
    }
    svg {
        width: 1rem;
        height: 1rem;
    }
}
.slide-up-down {
    height: 3rem !important;
    transition: height .2s ease !important;
    opacity: 1 !important;
    cursor: pointer !important;
}
.menu-box {
    display: none !important;
    background-color: unset;
    border: none;
    padding: 0;
    box-shadow: none;
    min-height: 2rem;    
  
    span {
      background-color: $front-light-pink;
      display: block;
      margin-bottom: 0.5rem;
      width: 2.3rem;
      height: 0.2rem;
      border-radius: 5rem;
      transition: all 0.5s ease;
  
      &:last-of-type {
        margin-bottom: 0;
      }
    }
}
.common-title {
    margin-bottom: 0.8rem;
    .badge-title {
        display: inline-block;
        background-color: rgba($front-secondary, 0.16);    
        color: $front-secondary;
        padding: 0.2rem 1.2rem;
        font-size: 1.1875rem;    
        font-weight: 600;
        line-height: normal;
        border-radius: 1.25rem;
        margin-bottom: 0.6rem;
    }
    .title-box {
        color: $black;
        font-size: 3.4rem;    
        font-weight: 700;
        line-height: 4.1rem;    
    }
    p {                       
        width: 100ch;       
        margin-top: 1rem !important;
        margin: 0 auto;
    }
}

header {
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 9999;    
    .nav {
        align-items: center;
        gap: 1.5rem;
        .nav-link {
            font-size: 1.375rem;
            color: $black;
            font-weight: 400;
            line-height: normal;
            position: relative;            
            &:hover, &:focus, &.active {
                color: lighten($front-light-pink, 10%);
                &::before {
                    opacity: 1;
                }
            }         
            &::before {
                content: "";
                width: 0.4rem;
                height: 0.4rem;
                position: absolute;
                bottom: -2%;
                margin: 0 auto;
                left: 0;
                right: 0;
                border-radius: 50%;
                opacity: 0;
                transition: all 0.4s ease;
                background: linear-gradient(30deg, #FF5A9C 0%, #FF6DA8 31.43%, #FF94BF 75.47%, #FFBCD7 100%);
            }   
        }
    }
}

.main-banner {
    padding: 0;
    scroll-padding-top: 0;
    .main-inner-banner{
        .slick-slider{
            .slick-arrow {
          &.slick-prev,&.slick-next{
            width: 2.4rem;
            height: 2.4rem;
            background: #ffffff4d;
            position: absolute;
            z-index: 10;
            border-radius: 2rem;
            transition: all 0.4s ease;
            display: flex !important;
            align-items: center;
            justify-content: center;
            &::before{
              content: "" !important;      
              background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="26" viewBox="0 0 15 26" fill="none"><path d="M1.05099 25L13.1562 12.8947L1.05099 0.789474" stroke="white" stroke-width="2"/></svg>');                                      
              background-repeat: no-repeat;
              background-size: 100% 100%; 
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1.2rem;
              height: 1.2rem;
              margin: 0 auto;
              transition: all 0.4s ease;
            }
            &:hover{
              background-color: #baf61099;               
             }
          }
          &.slick-prev {
            left: 1%;
           &::before{
            transform: rotate(180deg);       
            margin-left: 0.6rem;
           }
          }
          &.slick-next {
            right: 1%;
           &::before{
            margin-left: 0.8rem;
           }
          }
        }
        }
    }
    
    .slick-slide {
        padding: 0 0.5rem;
            .slide-1 {
                background-image: url('../assets/front-images/banner-img.png');
                .img-box {
                    img {                        
                        max-width: 100%;                        
                    }    
                }
            }
            .slide-2 {
                background-image: url('../assets/front-images/banner-img2.png');
            }
            .slide-3 {
                background-image: url('../assets/front-images/banner-img3.png');
            }
    }
    .slick-dots {
        position: absolute;
        bottom: 5%;
        left: 3%;        
        text-align: left;
        width: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;
        gap: 1.2rem;
        li {
            margin: 0;
            width: auto;
            height: auto;
            &:first-child {
                margin-left: 0;
            }
            &.slick-active {
                button {
                    &::before {
                        opacity: 1;
                        font-size: 1.8rem;
                        color: $white;
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                }
            }  
            button {
                width: auto;
                height: auto;
                padding: 0;                
                &::before {
                    opacity: .7;                    
                    color: $white;
                    font-size: 1rem;
                    content: "";
                    display: block;
                    position: inherit;
                    width: 1rem;
                    height: 1rem;
                    background-color: $white;
                    border-radius: 50%;
                }
            }          
        }
    }
    .banner-slide {        
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: calc(100vh - 12rem);
        border-radius: 2.2rem;
        display: flex;
        align-items: center;       
        .slide-content {
            flex-basis: 50%;
            padding-left: 3rem;
            h1 {
                font-size: 4.3rem;
                color: $white;
                font-weight: 700;
                line-height: 5.1rem;
                margin-bottom: 1rem;
            }
            p {
                color: $white;
                margin-bottom: 2rem;
            }
            span {
                display: flex;
                gap: 1.5rem;
                a {
                    transition: all 0.4s ease;
                    img{
                        max-width: 100%;
                        width: 100%;
                        height: 100%;
                    }
                    &:hover {
                        animation: pulse 1.4s linear infinite;
                    }
                }
            }
        }        
        .img-box {            
            width: auto;
            height: auto;
            position: relative;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;            
            &::before {
                content: "";
                width: 35rem;
                height: 35rem;
                border-radius: 50%;
                border: 2px dashed $white;                
                position: absolute;
                z-index: 0;             
                animation: rotate 55s linear infinite;     
                transform-origin: center;
                transform-box: fill-box;        
            }
            &::after {
                content: "";
                width: 29rem;
                height: 29rem;
                border-radius: 50%;
                background-color: lighten($front-secondary, 37%);                
                position: absolute;
                z-index: 0;                       
            }
            img {
                z-index: 9;
                position: relative;
                max-width: 85%;
                margin: 0 auto;
            }            
        }
    }
}

.about-us {
    position: relative;
    .row {
        align-items: center;
    }
    .img-box {
        position: relative;
        img {            
            display: block;
            margin: 0 auto;        
            position: relative;
            z-index: 1;
        }
        &::before {
            content: "";
            width: 23rem;
            height: 23rem;
            background-color: lighten($front-secondary, 20%);
            filter: blur(5rem);
            position: absolute;
            top: 10%;
            left: 35%;
            border-radius: 50%;
            z-index: 0;
        }
        &::after {
            content: "";
            width: 21rem;
            height: 21rem;
            background-color: lighten($front-neon, 20%);
            filter: blur(5rem);
            position: absolute;
            top: 45%;
            left: 30%;
            border-radius: 50%;
            z-index: 0;
        }
        .shape-box {
            position: absolute;
            top: 2%;
            left: 11%;
            z-index: 0;
        }
    }
    .content-box {      
        p {
            font-size: 1.375rem;
            color: $gray-600;
            letter-spacing: 0.05rem;
            line-height: 2.1rem
        }  
    }
    .about-us-shapes {
        img, svg {
            position: absolute;            
            &:first-child {
                right: 5%;
                top: 10%;
                animation: boat 3s linear infinite;
            }
            &:last-child {
                left: -4%;
                bottom: -12%;                
                #plane {
                    animation: paper-plane-soaring 4s forwards infinite;
                }
                // #wave {
                //     animation: paper-plane-soaring 4s forwards infinite;
                // }
            }
        }
    }
}

.process {
    position: relative;
    .process-inner {
        background-color: rgba($front-secondary, 0.08);
        border-radius: 1.8rem;
        padding: 3.1rem 0;
        .process-list {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 1rem;
            margin-top: 2rem;
            .process-item {
                padding: 0 3.6rem;
                position: relative;
                span {
                    background-color: rgba($front-orange, 0.6);                                      
                    color: $white;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 4.3rem;
                    height: 4.3rem;
                    border-radius: 50%;
                    font-size: 1.8rem;
                    font-weight: 600;
                    line-height: normal;      
                    margin-bottom: 1.8rem;           
                    animation: pulse-process 2s infinite;   
                }
                h4 {
                    color: $black;                                        
                    font-size: 1.8rem;                                        
                    line-height: normal;
                    letter-spacing: 0.03rem;
                    max-width: 15ch;
                    margin: 0 auto;
                    margin-bottom: 1rem;
                }
                p {
                    color: $gray-600;
                }
                &:not(:last-child) {
                    &::after {
                        content: "";
                        border-top: 2px dashed $gray-400;
                        border-end-start-radius: 0.9rem; 
                        position: absolute;
                        right: -30%;
                        width: 50%;
                        top: 10%;
                    }
                }
            }
        }
    }
    .process-shapes {
        img, svg {
            position: absolute;                 
            right: 0%;
            top: 4%;      
            opacity: 0.5;       
            #hand {
                animation: hand 1.5s linear infinite;
                transform-origin: bottom;
                transform-box: fill-box;
            }   
            #hand2 {
                animation: hand2 2s linear infinite;
                transform-origin: bottom;
                transform-box: fill-box;
            }   
            #hair {
                animation: hair 2s linear infinite;
                transform-origin: bottom;
                transform-box: fill-box;
            }
        }
    }
}

.features {
    position: relative;
    .common-title {
        h2 {
            max-width: 18ch;
            margin: 0 auto;
        }
    }
    .features-list {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 3rem;
        margin: 0 7rem;
        margin-top: 3rem;
        .features-item {            
            width: 100%;
            height: 24rem;
            // border-radius: 46% 39% 73% 7%/82% 3% 2% 19%;                        
            display: flex;
            align-items: center;
            gap: 2rem;            
            position: relative;     
            padding: 1.3rem 0;      
            // > img {
            //     flex: none;            
            // }
            .content-box {
                padding-right: 2rem;
                max-width: 56%;
                h4 {
                    color: $black;                                        
                    font-size: 1.8rem;                                        
                    line-height: normal;
                    letter-spacing: 0.03rem;                    
                    margin-bottom: 0.8rem;
                }
                p {
                    color: $gray-600;
                }
            }
            &::before {
                content: "";
                clip-path: polygon(33% 0, 100% 0, 100% 100%, 0% 100%);
                border-radius: 1rem;
                background-color: lighten($front-neon, 44%);
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: -1;
            }
            &:nth-child(2) {
                &::before {
                    background-color: lighten($front-secondary, 46%);
                }
            }
            &:nth-child(3) {
                &::before {
                    background-color: lighten($primary, 38%);
                }
            }
            &:nth-child(4) {
                &::before {
                    background-color: lighten($danger, 53%);
                }
            }
        }
    }   
    .features-shapes {
        img {
            position: absolute;                              
            opacity: 0.5;     
            // &:first-child {
            //     top: 6%;
            //     left: 6%;
            //     #wave {
            //         animation: rotateslow 18s linear infinite;
            //         transform-origin: center;
            //         transform-box: fill-box;
            //     }
            // }        
            &:last-of-type {
                top: 14%;
                right: 0;                
            }
        }
        svg {
            position: absolute;                              
            opacity: 0.5;     
            &:first-child {
                top: 6%;
                left: 6%;
                #wave {
                    animation: rotateslow 18s linear infinite;
                    transform-origin: center;
                    transform-box: fill-box;
                }
            }  
        }
    }
}

.subscribe {
    position: relative;
    margin: 2% 6%;
    .subscribe-inner {
        background: linear-gradient(323deg, #6E5FCC 0%, #898DEA 40.28%, #A395F9 71.26%, #CFC7FF 100%);
        border-radius: 1.8rem;
        display: flex;
        gap: 4rem;
        align-items: center;
        max-height: calc(75vh - 17rem);
        padding: 0 5rem;     
        position: relative;           
        .content-box {
            h3 {
                color: $white;
                font-size: 2.6rem;                                
                letter-spacing: 0.05rem;
                margin-bottom: 2rem;
            }
            .form-group {
                position: relative;
                width: 70%;
                .form-control {                    
                    border-radius: 3rem;
                    padding-right: 13rem;
                    padding-left: 1.4rem;
                    background-color: $white !important;
                    color: $gray-700;
                    height: 3.8rem;
                    align-items: center;
                    border-color: transparent;
                    font-size: 1.4rem;
                    letter-spacing: 0.02rem;
                    &:focus, &:hover {
                        border-color: $primary;
                    }
                    &::placeholder {
                        color: $gray-500;
                    }
                }
                .btn-primary {
                    border-radius: 3rem;
                    padding: 0.5rem 3rem;
                    position: absolute;
                    right: 1%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .subscribe-inner-shapes {
            img {
                position: absolute;                
                opacity: 0.5;
                &:first-child {
                    top: -19%;
                    left: 19%;
                }
                &:nth-child(2) {
                    top: 6%;
                    right: 23%;
                }
                &:nth-child(3) {
                    bottom: 3%;
                    left: 36%;
                }
            }
        }
    }
    .subscribe-shapes {
        img, svg {
            position: absolute;
            top: -18%;
            right: 3%;
            opacity: 0.5;      
            animation: balloonmove 10s infinite ease-in-out;       
        }
    }
}


.plans {
    position: relative;
    .plans-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 2rem;
        margin-top: 3rem;
        .card {
            border: 0;
            border-radius: 1.8rem;
            padding: 2rem;
            padding-bottom: 25%;
            position: relative;
            overflow: hidden;
            border: 2px solid transparent;
            transition: all 0.3s ease;
            &:hover {
                &:first-child {
                    background-color: lighten($front-secondary, 38%);
                    border: 2px solid $front-secondary;
                }
                &:nth-child(2) {
                    background-color: lighten($front-orange, 41%);
                    border: 2px solid lighten($front-orange, 30%);                    
                }
                &:last-of-type {
                    background-color: lighten($front-pink, 41%);
                    border: 2px solid lighten($front-pink, 30%);
                }
            }
            &:first-child {
                background-color: lighten($front-secondary, 45%);                
            }
            &:nth-child(2) {
                background-color: lighten($front-orange, 45%);                
                .plans-inner-shapes {                 
                    right: -20%;                    
                }
            }
            &:last-of-type {
                background-color: lighten($front-pink, 45%);                
            }
            h5 {
                color: $black;
                font-size: 2.2rem;                
                margin-bottom: 0.2rem;
            }
            h3 {
                color: $black;
                font-size: 3.7rem;                
                margin-bottom: 1rem;
            }
            ul {
                li {
                    margin-bottom: 1rem;
                    display: flex;
                    gap: 0.6rem;
                    align-items: center;
                    color: $gray-600;   
                    font-size: 1.4rem;
                    z-index: 1;
                    position: relative;
                    svg {
                        color: lighten($primary, 10%);
                    }
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
            .plans-inner-shapes {
                position: absolute;
                bottom: -10%;
                right: -11%;
                opacity: 0.5;           
                z-index: 0;     
            }
        }
        .btn-primary {
            border-radius: 3rem;
            padding: 0.5rem 3rem;      
            margin-top: 2rem;     
            z-index: 1; 
        }
    }    
    .plans-shapes {
        position: absolute;
        top: 4%;
        left: 5%;
        opacity: 0.5;        
        img, svg {
            // #hand {
            //     animation: hand2 2s linear infinite;
            //     transform-origin: bottom;
            //     transform-box: fill-box;
            // }
            // #hand2 {
            //     animation: hand 2s linear infinite;
            //     transform-origin: bottom;
            //     transform-box: fill-box;
            // }
            animation: girlplay 2s linear infinite;
        }
    }
}

.testimonials {
    padding-top: 0;
    .common-title {
        h2 {
            max-width: 22ch;
            margin: 0 auto;
        }
    }
    .testimonials-list {        
        margin-left: 8rem;
        .card {
            padding: 3rem;
            border-radius: 1.8rem;
            background: linear-gradient(48deg, #6E5FCC 0%, #898DEA 38.27%, #A395F9 63.54%, #CFC7FF 100%);
            box-shadow: 0px 4px 7px 3px rgba(0, 0, 0, 0.25);
            border: none;
            svg, p{
                color: $white;
                margin-bottom: 1.1rem;
            }
            p {
                font-size: 1.5rem;
            }
            .testimonial-user {
                display: flex;
                gap: 0.7rem;
                align-items: center;
                h6 {
                    color: $white;
                    font-size: 1.2rem;                                        
                    margin-bottom: 0.2rem;
                }
                span {
                    color: $white;
                    font-size: 1rem;                                                            
                }
            }
        }
    }

    .slick-track {
        margin-left: -8.8rem;
    }
    .slick-slide {
        padding: 1.2rem 1.5rem;        
    }
    .slick-dots {
        position: absolute;
        bottom: -16%;        
        text-align: center;        
        li {
            margin: 0 0.5rem;
            &:first-child {
                margin-left: 0;
            }
            &.slick-active {
                button {
                    &::before {
                        opacity: 1;
                        font-size: 0.8rem;
                        color: $primary;
                    }
                }
            }  
            button {
                &::before {
                    opacity: .7;                    
                    color: $gray-400;
                    font-size: 0.8rem;
                }
            }          
        }
    }
    .slick-prev, .slick-next {
        top: auto !important;        
        bottom: -26%;        
        width: auto;
        height: auto;
        text-align: center;
        z-index: 9;
        &::before {
            color: $primary !important;
            font-size: 3.1rem !important;
            opacity: 1;
        }
    }
    .slick-prev {
        left: 42% !important;
    }
    .slick-next {
        right: 42% !important;        
    }
}


.call-to-action {
    position: relative;
    padding: 10% 8%;
    padding-bottom: 5rem;
    .call-to-action-inner {
        border-radius: 1.8rem;
        background: linear-gradient(141deg, #FFC7CC 26.52%, rgba(215, 146, 240, 0.74) 71.45%, rgba(175, 144, 240, 0.34) 100%);
        display: flex;
        gap: 4rem;
        align-items: center;
        max-height: calc(80vh - 12rem);
        padding: 0 5rem;     
        h3 {
            font-size: 2.875rem;            
            width: 19ch;
            margin-bottom: 1rem;
        }          
        p {
            font-size: 1.3rem;
            letter-spacing: 0.04rem;            
            margin-bottom: 1.6rem;
            color: $gray-600;
        }
        span {
            display: flex;
            gap: 1.5rem;
        }        
        > img {
            align-self: flex-end;
            margin-bottom: 2rem;
        }
    }    
    .call-to-action-shapes {
        img, svg {
            position: absolute;
            opacity: unset;
            &:first-child {
                top: -1%;
                left: -2%;
                animation: wood 4s linear infinite;
                transform-origin: left;
                transform-box: fill-box;
            }
            &:last-of-type {
                top: -7%;
                right: 1.2%;                                
            }
        }
    }
}

footer {
    background:linear-gradient(71deg, #9390F0 25.69%, rgba(176, 146, 240, 0.83) 79.67%, rgba(144, 148, 240, 0.74) 100%);
    border-radius: 1.3rem;
    margin: 0.5rem;
    margin-top: 0;
    padding: 2.5rem 0;
    h4 {
        font-size: 1.8rem;                        
        color: $white;
        margin-bottom: 1.3rem;    
    }
    a {
        display: inline-block;
    }
    ul {
        li {
            margin-bottom: 0.6rem;
            &:last-of-type {
                margin-bottom: 0 !important;
            }
            a {
                color: $white;
                font-size: 1.2rem;
                letter-spacing: 0.03rem;
                &:hover, &:focus {
                    color: lighten($front-light-pink, 13%);
                }
            }
        }
        &.contact-list {
            li {
                a {
                    display: inline-flex;
                    align-items: center;
                    gap: 0.8rem;
                }
            }
        }
        &.quick-links {
            li {
                list-style: disc;
                color: $white;
                margin-left: 1.2rem;
                &::marker {
                    line-height: normal;                   
                }
            }
        }
    }
    .copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        border-radius: 1rem;
        padding: 0.8rem 1.4rem;
        p {
            color: $gray-600;
        }
        ul {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            li {
                margin-bottom: 0;
            }
            a {
                width: 2.6rem;
                height: 2.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                background: linear-gradient(30deg, $primary 0%, $primary 31.43%, $primary 75.47%, $primary 100%);
                border-radius: 50%;
                &:hover, &:focus {
                    background: linear-gradient(30deg, #FF5A9C 0%, #FF6DA8 31.43%, #FF94BF 75.47%, #FFBCD7 100%);
                    color: $white;
                }
            }
        }
    }
}

// responsive
@media screen and (max-width:1919px) {
    .main-banner {
        .banner-slide {
            .slide-content {
                h1 {
                    font-size: 3.4rem;                
                    line-height: 3.8rem;
                }
            }
            &.slide-1 {                   
                .img-box {
                    img {                        
                        max-width: 84%;                        
                    }    
                }
            }
            .img-box {
                img {
                    max-width: 70%;
                }                                       
                &::after {                    
                    width: 25rem;
                    height: 25rem;
                }
                &::before {                    
                    width: 30rem;
                    height: 30rem;
                }    
            }
        }        
    }    
    .about-us {
        .img-box {
            .animated {
                > img {
                    max-width: 42%;
                }
            }
        }               
    }    
    .features {
        .features-shapes {
            img, svg {
                &:last-of-type {
                    top: 4%;
                }
            }
        }      
        .features-list {
            .features-item {
                .content-box {
                    padding-right: 1.1rem;
                    max-width: 62%;
                }  
            }
        }
    }
    .plans {
        .plans-inner-shapes {
            max-width: 70% !important;
        }
    }
    .subscribe {
        .subscribe-inner {       
            max-height: calc(75vh - 10rem);
        }
    }
    .testimonials {
        .slick-track {
            margin-left: -7.6rem;
        }
    }
    .call-to-action {
        .call-to-action-shapes {
            img, svg {
                &:last-of-type {                   
                    max-width: 18%;
                }
                &:first-child {                 
                    max-width: 13%;
                }
            }
        }
    }
    .main-features {
        .main-features-data {         
            max-width: 85%;
        }
    }
}

@media screen and (max-width:1599px) {
    .main-banner {     
        .banner-slide {
            min-height: calc(100vh - 9rem);
            .slide-content {
                flex-basis: 58%; 
                h1 {
                    font-size: 3.2rem;
                    line-height: 3.3rem;
                }
                span {
                    a {
                        display: inline-block;
                        width: 18%;                       
                    }                    
                }
            }
            &.slide-1 {                   
                .img-box {
                    img {                        
                        max-width: 80%;                        
                    }    
                }
            }
            .img-box {
                img {
                    max-width: 66%;
                }                
            }
        }    
        .slick-dots {            
            li {                
                &.slick-active {
                    button {
                        &::before {
                            width: 1.2rem;
                            height: 1.2rem;
                        }
                    }
                }
                button {                 
                    &::before {
                        width: 0.8rem;
                        height: 0.8rem;
                    }                    
                }
            }            
        }    
    }
    .common-title {     
        .title-box {
            font-size: 2.9rem;        
            line-height: 3.5rem;
        }       
    }
    p {
        font-size: 1.175rem !important;     
        line-height: 1.8rem !important;
    }
    .about-us {     
        .img-box {
            &::after {
                width: 17rem;
                height: 17rem;
            }
            &::before {         
                width: 20rem;
                height: 20rem;
            }
            .shape-box {
                img, svg {
                    max-width: 79%;
                }
             }
        }                
    }
    .about-us-shapes {
        img, svg {            
            &:first-child {
                max-width: 6%;
            }
            &:last-child {
                max-width: 19%;
            }
        }
    }
    .process {
        .process-item {
            padding: 0 2rem !important;
        }        
    }
    .features {     
        .features-list {
            margin: 0;
            .features-item {
                .content-box {                    
                    max-width: 64%;
                }
            }
        }        
    }
    .subscribe {
        .subscribe-inner {       
            max-height: calc(75vh - 8rem);
            padding: 0 2rem;
            > img {
                max-width: 16%;
            }            
            .content-box {
                .form-group {                    
                    width: 78%;
                }
                h3 {
                    font-size: 2.3rem;
                }
            }
        }
        .subscribe-shapes {
            img, svg {                               
                max-width: 10%;
                top: -15%;
            }
        }
    }    
    .plans {
        .plans-shapes {     
            top: 3%;            
        }
        .plans-list {
            .card {
                h5 {                 
                    font-size: 1.8rem;
                }
                h3 {
                    font-size: 2.6rem;
                    margin-bottom: 0.7rem;
                }
                ul {
                    li {
                        margin-bottom: 0.8rem;                        
                        font-size: 1.2rem;
                        svg {
                            width: 1.3rem;
                        }
                    }
                }
            }
        }
    }
    .testimonials {
        .testimonials-list {
            margin-left: 4rem;
        }
        .slick-slide {
            padding: 1.2rem 0.9rem;
        }
        .slick-track {
            margin-left: -6.2rem;
        }
        .slick-prev, .slick-next {            
            font-size: 2.6rem !important;
        }
        .slick-next {
            right: 33% !important;
        }
        .slick-prev {
            left: 40% !important;
        }
    }
    .call-to-action {
        .call-to-action-inner {       
            max-height: calc(80vh - 10rem);        
            > img {
                max-width: 34%;
            }
        }
    }

    .call-to-action  {
        .call-to-action-inner {
            span {
                a {
                    display: inline-block;
                    width: 18%;                       
                }                    
            }
        }         
    }
    
     .main-about-us {
         .img-box {
             img {
                 max-width: 45% !important;
             }
             .shape-box {
                 svg {
                     &:first-child {      
                         max-width: 85%;
                     }
                     &:last-child {
                         max-width: 78%;
                     }
                 }
             }
         }
     }    
     .we-offer {
        .we-offer-list {
            max-width: 83% !important;
            .we-offer-detail-item {
                h4 {
                    font-size: 1.7rem;
                }
            }
         }
     }
     .inner-page-banner {       
        min-height: calc(100vh - 28.2rem) !important;
     }

     .main-features {
        .main-features-data {
            max-width: 90%;
            border-radius: 1rem 6rem 1rem 6rem;
            padding: 2rem;
            h4 {
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
                line-height: 2rem;
            }
        }
     }
     .mockup-img-box {
        .shape-box {
            > div {
                &:last-child {       
                    max-width: 95%;
                }
                &:first-child {
                    max-width: 100%;
                }
            }
        }
     }
     .main-benefits {
        ul {
            li {
                padding: 1rem 1rem;
            }
        }
     }
    .main-benefits-third {
        .main-benefits-third-inner {
            .content-box {
                h3 {        
                    font-size: 2.2rem;
                }
            }
        }
    }
}

@media screen and (max-width:1439px) {
    .main-banner {
        .banner-slide {
            .img-box {
                &::before {
                    width: 27rem;
                    height: 27rem;
                }
                &::after {
                    width: 22rem;
                    height: 22rem;
                }
            }
            .slide-content {
                h1 {
                    font-size: 2.6rem;
                    line-height: 3rem;                  
                }
            }
        }
    }
    header {
        .nav {
            gap: 0.8rem;
            .nav-link {
                font-size: 1.1rem;
            }
        }
    }
    .common-title {
        .badge-title {           
            font-size: 0.9rem;
        }
        .title-box {
            font-size: 2.6rem;
            line-height: 3.3rem;
        }
    }
    .features {
        .features-shapes {
            img {
                &:last-of-type {        
                    max-width: 21%;
                    top: 7%;
                }
                // &:last-of-type {
                //     max-width: 21%;
                // }
            }
            svg {
                &:last-of-type {
                    max-width: 10%;
                }
            }
        }
        .features-list {
            .features-item {
                .content-box {
                    h4 {
                        font-size: 1.6rem;                        
                        margin-bottom: 0.4rem;
                    }
                }
            }
        }
    }
    .process {            
        .process-item {
            span {       
                width: 3.7rem !important;
                height: 3.7rem !important;        
                font-size: 1.5rem !important;
                outline-width: 0.45rem !important;
                margin-bottom: 1.1rem !important;
            }
            h4 {
                font-size: 1.6rem !important;
                margin-bottom: 0.6rem !important;
            }
        }            
    }
    .subscribe {       
        margin: 2% 3%;
        .subscribe-inner {
            > img {
                max-width: 17%;
            }
        }
    }
    .testimonials {
        .slick-prev, .slick-next {
            &::before {
                font-size: 2.7rem !important;
            }          
        }              
    }
    .plans {
        .plans-shapes {
            top: 5%;
            img, svg {
                max-width: 80%;
            }
        }
    }
    .testimonials {
        .testimonials-list {
            .card {
                padding: 2rem;
            }
        }
    }
    .call-to-action {
        padding: 10% 5%;
        padding-bottom: 5%;
        .call-to-action-shapes {
            img, svg {
                &:last-of-type {
                    max-width: 15%;
                }
            }
        }
        .call-to-action-inner {
            max-height: calc(80vh - 9.1rem);
            gap: 1rem;
            padding: 0 4rem;
            h3 {
                font-size: 2.375rem;
            }
            > img {
                max-width: 36%;
            }
        }
    }
    footer {
        h4 {
            font-size: 1.5rem;       
            margin-bottom: 0.9rem;
        }
        ul {
            li {
                a {         
                    font-size: 1rem;
                }
            }
        }
        .copyright {           
            padding: 0.4rem 1rem;
        }
    }

    .we-offer-detail-item {
        span {        
            font-size: 3.7rem !important;
        }
        h4 {
            font-size: 1.7rem !important;
        }
    }
    .main-benefits-third {
        .main-benefits-third-inner {
            .form-control {                      
                height: 3.1rem !important;
            }
        }
    }
    .main-benefits-last {
        li {       
            max-width: 90%;
        }
    }
}

@media screen and (max-width:1365px) {
    p {
        font-size: 1.075rem !important;
        line-height: 1.6rem !important;
    }
    section {
        padding: 4rem 0;
    }
    .container {
        max-width: calc(100% - 7rem);
        margin: 0 4rem;
    }
    footer {
        padding: 1.7rem 0;
        .copyright {
            ul {
                a {
                    width: 2.1rem;
                    height: 2.1rem;
                    svg {
                        width: 1.1rem;
                        height: 1.1rem;
                    }
                }
            }
        }
    }
    .call-to-action {
        .call-to-action-inner {
            max-height: calc(80vh - 11.2rem);
            > img {
                max-width: 31%;
            }
        }
        .call-to-action-shapes {
            img, svg {
                &:last-of-type {                    
                    right: -0.8%;
                }
            }
        }
    }    
    .main-banner {
        .banner-slide {
            .img-box {
                &::before {
                    width: 26rem;
                    height: 26rem;
                }
                &::after {
                    width: 20rem;
                    height: 20rem;
                }
            }
            .slide-content {
                padding-left: 3.3rem;
                h1 {
                    font-size: 2.4rem;
                    line-height: 2.8rem;                  
                }
            }
        }
    }
    .about-us {
        .img-box {
            &::before {
                width: 14rem;
                height: 14rem;
            }
            &::after {
                width: 14rem;
                height: 14rem;
            }
        }
    }
    .common-title {
        .title-box {
            font-size: 2.3rem;
            line-height: 2.8rem;
        }
    }
    .process {
        .process-shapes {
            img, svg {       
                max-width: 11%;
            }
        }
        .process-item {
            padding: 0 1rem !important;
            span {
                width: 3.4rem !important;
                height: 3.4rem !important;
                font-size: 1.4rem !important;
                outline-width: 0.45rem !important;                
            }
        }
    }
    .features {
        .features-list {
            margin: 0 0;
        }
    }
    .subscribe {
        .subscribe-inner {
            .content-box {
                h3 {
                    font-size: 2rem;
                }
                .form-group {
                    .form-control {                    
                        padding-right: 11.4rem;                                                            
                        height: 3rem;                    
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }
    .plans {
        .plans-shapes {
            top: 2%;
        }
        .plans-list {
            .card {              
                padding: 1.5rem;
                padding-bottom: 16%;
            }
        }
    }
    .testimonials {
        .slick-prev, .slick-next {
            bottom: -23%;
            &::before {
                font-size: 2.3rem !important;
            }
        }        
    }    
    .our-mission-inner {       
        padding: 1rem 2.5rem !important;
    }
    .inner-page-banner {
        h2 {     
            font-size: 2.7rem !important;
        }
        .breadcrumb {
            a, li {
                font-size: 1.1rem !important;
            }
        }
    }
    .main-benefits-second {
        .main-benefits-second-inner {
            ul {
                grid-gap: 1rem;
            }
            li {
                span {
                    font-size: 2.8rem;       
                    top: -1%;
                }
                h5 {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media screen and (max-width:1279px) {
    p {
        font-size: 0.875rem !important;
        line-height: 1.3rem !important;
    }
    .container {
        max-width: calc(100% - 4rem);
        margin: 0 2rem;
    }
    .main-banner {
        .banner-slide {
            &.slide-1 {                   
                .img-box {
                    img {
                        max-width: 68%;                        
                    }    
                }
            }
            .img-box {
                &::before {
                    width: 23rem;
                    height: 23rem;
                }
                &::after {
                    width: 18rem;
                    height: 18rem;
                }
            }
            .slide-content {
                h1 {
                    font-size: 1.9rem;
                    line-height: 2.3rem;
                }
            }
        }
    }
    .features {
        .features-list {
            .features-item {        
                height: 18rem;
            }
        }
    }
    .plans {
        .plans-list {       
            grid-gap: 1.2rem;
        }
    }
    .testimonials {
        .slick-track {
            margin-left: -4.7rem;
        }
        .slick-dots {         
            bottom: -10%;
        }
        .testimonials-list {
            .card {
                svg {
                    width: 2.2rem;
                    height: 1.7rem;
                }
                .testimonial-user {
                    img {
                        max-width: 3.2rem;
                    }
                }
            }
        }
    }
    .call-to-action {
        .call-to-action-inner {
            max-height: calc(80vh - 9.2rem);
        }
        .call-to-action-shapes {
            svg {
                &:last-of-type {
                    top: -24%;            
                }
            }
        }
    }
    .subscribe {
        .subscribe-shapes {
            img {
                max-width: 11%;
                top: -20%;
            }
        }
    }

    .inner-page-banner {
        min-height: calc(100vh - 22.2rem) !important;
    }
    .we-offer {
        .we-offer-list {
            max-width: 90% !important;
            grid-gap: 2.5rem !important;
        }
        .we-offer-detail-item {
            h4 {
                font-size: 1.5rem !important;
            }
        }
    }
    .main-features {
        .main-features-data {
            max-width: 100%;
        }
    }
    .mockup-img-box {
        img {        
            max-width: 60% !important;
        }
        .shape-box {
            > div {
                &:last-child {
                    max-width: 100%;
                }
            }
        }
    }
    .main-benefits-second {
        .main-benefits-second-inner {        
            border-radius: 1.4rem;
            padding: 1.5rem 1.5rem;
        }
    }
    .main-benefits-third {
        .main-benefits-third-inner {     
            padding: 1.5rem 1rem;
            .content-box {
                h3 {
                    font-size: 1.8rem;
                }
            }
        }
    }
    .main-benefits-last {
        li {              
            margin-bottom: 0.9rem;
        }
    }
}

@media screen and (max-width:1023px) {
    .container {
        max-width: calc(100% - 3rem);
        margin: 0 1.5rem;
    }
    .common-title {
        .title-box {
            font-size: 1.9rem;
            line-height: 2.4rem;
        }
        p {
            width: 100%;
        }
    }
    header {
        .navbar-brand {
            img {
                max-width: 70%;
            }
        }
        .nav {
            gap: 0.2rem;
            .nav-link {
                font-size: 1rem;
            }
        }
    }
    .main-banner {
        .banner-slide {
            min-height: 28rem;            
            .img-box {
                width: 55%;
                img {
                    max-width: 66%;
                }
                &::before {
                    width: 18rem;
                    height: 18rem;
                }
                &::after {
                    width: 15rem;
                    height: 15rem;
                }
            }
            .slide-content {
                h1 {
                    font-size: 1.8rem;
                    line-height: 2rem;
                    margin-bottom: 0.5rem;
                }
                p {
                    margin-bottom: 0.8rem;
                }
                span {
                    a {
                        width: 32%;
                    }
                }
            }
            &.slide-1 {
                .img-box {
                    img {
                        max-width: 48%;
                    }
                }
            }
        }
        .slick-dots {            
            bottom: 6%;
            left: 5%;
            li {                
                &.slick-active {
                    button {
                        &::before {
                            width: 1.1rem;
                            height: 1.1rem;
                        }
                    }
                }
            }
        }
        .main-inner-banner{
            .slick-slider{
                .slick-arrow{
                    &.slick-next{
                        right: 2%;
                    }
                    &.slick-prev{
                        left: 2%;
                    }
                }
            }
        }  
    }        
    .about-us {      
        .row {
            display: block;
            .col-6 {
                width: 100%;
            }
        }  
        .about-us-shapes {
            img, svg {
                &:first-child {
                    max-width: 10%;
                }
                &:last-child {                    
                    bottom: auto;
                    max-width: 28%;
                    top: 25%;
                }
            }
        }
        .img-box {
            .animated {
                > img {
                    max-width: 29%;
                }
            }
            .shape-box {
                top: -18%;
                left: 24%;
                img, svg {
                    max-width: 56%;
                }
            }
        }
    }
    .process {
        .process-inner {
            padding: 1.7rem 0;
        }
        .process-item {
            padding: 0 0.4rem !important;
            h4 {
                font-size: 1.3rem !important;
                margin-bottom: 0.5rem !important;
            }
            span {
                width: 2.7rem !important;
                height: 2.7rem !important;
                font-size: 1.1rem !important;
                outline-width: 0.45rem !important;
            }
        }
        .process-shapes {
            img, svg {
                max-width: 15%;
                top: -10%;
            }
        }
    }
    .features {
        .features-shapes {
            img {                
                &:last-of-type {
                    max-width: 20%;
                }
            }
            svg {
                &:last-of-type {
                    max-width: 14%;
                    top: 1%;
                }
            }
        }
        .features-list {            
            // grid-template-columns: repeat(2, 1fr);
            grid-gap: 1.3rem;
            .features-item {
                height: auto;
                flex-direction: column;
                gap: 0.9rem;
                padding: 1.3rem;
                &::before {
                    top: 0;
                    clip-path: polygon(0% 40%, 100% 0, 100% 100%, 0% 100%);
                }
                > img {
                    max-width: 54%;
                    flex: none;
                }
                .content-box {
                    max-width: fit-content;
                }
            }
        }
    }
    .subscribe {
        margin: -1% 0%;
        .subscribe-inner {
            gap: 2rem;
            max-height: 14rem;
            .content-box {
                h3 {
                    font-size: 1.7rem;
                    margin-bottom: 1rem;
                }
                .form-group {
                    width: 93%;
                }
            }
        }
        .subscribe-inner-shapes {
            img {
                &:nth-child(2), &:nth-child(3) {          
                    max-width: 6%;
                }
                &:first-child {                
                    max-width: 20%;
                }
            }
        }
        .subscribe-shapes {
            img, svg {
                max-width: 12%;
                top: -26%;
            }
        }
    }
    .plans {
        .plans-list {
            .card {
                padding: 0.9rem;         
                padding-bottom: 28%;
                border-radius: 1.3rem;
                h5 {
                    font-size: 1.5rem;
                }
                h3 {
                    font-size: 2.1rem;
                    margin-bottom: 0.3rem;
                }
                ul {
                    li {
                        margin-bottom: 0.4rem;
                        font-size: 1.1rem;
                        gap: 0.4rem;
                        svg {
                            width: 1.2rem;
                        }
                    }
                }
            }
            .btn-primary {                             
                margin-top: 1rem;     
                padding: 0.4rem 1.7rem;   
                font-size: 0.975rem;        
            }
        }
        .plans-inner-shapes {
            max-width: 90% !important;
        }
    }
    .testimonials {
        .testimonials-list {        
            margin: 0 1rem;
        }
        .slick-slide {
            padding: 1.2rem 0.5rem;
        }
        .slick-track {
            margin-left: 0;
        }
    }
    .call-to-action {
        .call-to-action-inner {        
            gap: 0.3rem;
            padding: 0.9rem 1.8rem;
            max-height: 16rem;
            h3 {
                font-size: 1.875rem;
                width: 22ch;
                margin-bottom: 0.6rem;
            }
            p {
                margin-bottom: 0.9rem;
            }
            span {
                a {
                    width: 32%;
                }
            }
        }
        .call-to-action-shapes {
            img {
                &:last-child {
                    max-width: 16%;
                    top: -12%;
                }
                
            }
            svg {
                &:last-of-type {
                    max-width: 17%;
                    top: -34%;
                }
            }
        }
    }
    footer {
        padding-bottom: 1.1rem;
        .col-6 {
            &:first-child {
                width: 20%;
            }
        }       
        h4 {
            font-size: 1.4rem;
            margin-bottom: 0.5rem;
        }
        ul {
            li {
                margin-bottom: 0.3rem;
            }
        }
        .copyright {        
            padding: 0.4rem 0.6rem;
            margin-top: 0.8rem;
            border-radius: 0.7rem;
        }
        ul {
            &.contact-list {
                li {
                    a {
                        gap: 0.6rem;
                       svg {
                        width: 1.2rem;
                        height: 1.2rem;
                       }
                    }
                }
            }
        }
    }    
    .inner-page-banner {
        min-height: 13rem !important;
        .shape-box {
            svg {       
                max-width: 12%;
            }
        }
    }
    .main-about-us {
        .col-6 {
            width: 100%;
        }
        .img-box {
            margin-bottom: 0.5rem;
            img {
                max-width: 33% !important;
            }
            .shape-box {
                svg {
                    &:first-child {
                        max-width: 67%;
                    }
                    &:last-child {
                        max-width: 59%;
                    }
                }
            }
        }
        .common-title {
            .title-box {
                br {
                    display: none;
                }
            }
        }
    }
    .our-mission-inner {
        padding: 1.5rem 1.5rem !important;        
        .col-6 {
            width: 100%;
        }
        svg {
            max-width: 75% !important;
            margin: 0 auto;
            display: block;
        }
    }
    .we-offer {
        .we-offer-list {
            max-width: 100% !important;
        }
        .we-offer-detail-item {
            span {
                font-size: 3rem !important;
                margin-top: -1.2rem !important;
            }
        }
    }
    .mockup-img-box {
        display: none;
    }
    .main-features {
        .row {
            margin-top: 2rem;
        }
        .main-features-col {
            width: 50%;
            &:nth-child(2) {
                order: 3;        
                margin-top: 1.3rem;
            }
            .main-features-data {
                margin-bottom: 1.3rem;
                border-radius: 1rem 4rem 1rem 4rem;
                padding: 1.3rem 1.8rem;
            }
        }
    }
    .main-benefits {
        .row {
            margin-top: 1rem;
        }
        .col-6 {
            width: 100%;
        }
        ul {
            margin-top: 1.5rem;
        }
        svg {
            max-width: 65%;
            margin: 0 auto;
            display: block;
        }
    }
    .main-benefits-second {
        .main-benefits-second-inner {
            ul {
                grid-template-columns: repeat(2, 1fr);
            }        
        }
    }
    .main-benefits-third {
        .main-benefits-third-inner {            
            .form-group {     
                width: 75% !important;
                .form-control {
                    font-size: 1.2rem !important;
                }
            }            
        }
    }
    .main-benefits-last {
        .col-6 {
            width: 100%;
            order: 2;
            margin-bottom: 2rem;
            &:last-child {
                order: 1;
                margin-bottom: 1.2rem;
            }
        }
        ul {
            margin-bottom: 1rem;
            li {
                max-width: 100%;
            }
        }
        svg {
            max-width: 70%;
            margin: 0 auto;
            display: block;
        }
    }
    .terms-conditions-details {
        .terms-conditions-details-list {
            ul {
                li {                   
                    font-size: 1rem !important;
                    &::before {
                        top: 0.5rem !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .container {
        max-width: calc(100% - 2rem);
        margin: 0 1rem;
        .front-wrapper{
            .main-banner {
                .main-inner-banner{
                    .slick-slider{
                        .slick-arrow{
                            &.slick-prev{
                                display: none !important;
                            }
                            
                        }
                    }
                }  
            }
        }
    }
    p {     
        margin-bottom: 0.8rem;
    }
    header {        
        .nav {
            .nav-link {
                font-size: 0.9rem;
            }
        }
    }
    .main-banner {
        .banner-slide {
            min-height: 28rem;
            .slide-content {
                padding-left: 1.5rem;
                h1 {
                    font-size: 1.7rem;
                    line-height: 1.9rem;
                }
            }
            .img-box {
                &::before {
                    width: 16rem;
                    height: 16rem;
                }
                &::after {
                    width: 13rem;
                    height: 13rem;
                }
                img {
                    max-width: 80%;
                }
            }
        }
        .slick-dots {
            bottom: 3%;
            left: 4%;
            li {                
                &.slick-active {
                    button {
                        &::before {
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                }
                button {
                    &::before {
                        width: 0.6rem;
                        height: 0.6rem;
                    }
                }
            }
        }
        .main-inner-banner{
            .slick-slider{
                .slick-arrow{
                    display: none !important;
                }
            }
        }  
    }
    
    .testimonials {
        .slick-track {
            margin-left: 0;
        }
    }
    .features {
        .features-list {
            .features-item {
                > img {
                    max-width: 70%;
                }
            }
        }
    }
    footer {
        .col-6:first-child {
            width: 50%;
            img {
                max-width: 70%;
            }
        }
    }

    .inner-page-banner {
        h2 {
            font-size: 2.1rem !important;
        }
        .breadcrumb {
            li, a {
                font-size: 1rem !important;
            }
        }
    }
    .our-mission-inner {
        svg {
            max-width: 100% !important;
        }
    }
    .we-offer {
        .we-offer-list {            
            grid-gap: 1.8rem !important;
        }
    }

    .main-benefits-third {
        .main-benefits-third-inner {
            .content-box {
                h3 {
                    font-size: 1.4rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 639px) {
    section {
        padding: 2rem 0;
    }
    .menu-box {
        display: block !important;        
        line-height: normal;
        z-index: 999;
    }
    .menu-open {
        .menu-box {
          span {
            &:first-of-type {
              transform: rotate(42deg);
              margin: 0;
            }
    
            &:nth-child(2) {
              transform: rotate(-48deg);
              margin: 0;
              margin-top: -3px;
            }
    
            &:last-of-type {
              display: none;
            }
          }
        }
        .nav {            
            left: auto;
            right: 0;
            width: 100%;
            overflow-x: hidden !important;            
        }
    }    
    header {
        .nav {            
            background-color: $white;
            display: unset !important;
            position: fixed;
            top: 0;
            right: -100%;
            height: 100%;
            width: 100%;
            z-index: 9;
            transition: all 0.5s ease;
            padding: 4rem 0;
            padding-bottom: 0;
            .nav-link {        
                padding: 0.5rem 1rem;
                font-size: 1rem;
                &::before {
                    display: none;
                }
            }
            .btn-primary {
                margin-top: 0.4rem;
                font-size: 0.975rem;
                padding: 0.4rem 1.1rem;
                margin-left: 1rem;
            }
        }
    }
    .main-banner {
        .banner-slide {
            flex-direction: column;
            min-height: 38.3rem;
            gap: 1.6rem;
            padding: 2rem 0;
            justify-content: space-evenly;
            .slide-content {
                order: 2;
                padding: 0 1.3rem;
                text-align: center;
                span {
                    justify-content: center;
                }
            }
            .img-box {
                order: 1;
            }
        }
        .slick-dots {           
            right: 0;
            text-align: center;
            left: 0;
        }
    }
    .about-us {
        .about-us-shapes {
            img, svg {
                &:first-child {
                    max-width: 14%;
                }
                &:last-child {                    
                    max-width: 33%;
                    top: 24%;
                    left: -9%;
                }
            }
        }
        .img-box {
            margin-bottom: 1.1rem;
            .animated {
                > img {
                    max-width: 47%;
                }
            }
            &::before {
                width: 9rem;
                height: 9rem;
            }
            &::after {
                width: 11rem;
                height: 11rem;
            }
            .shape-box {             
                left: 1%;
                img, svg {
                    max-width: 65%;
                }
            }
        }
        .content-box {
            text-align: center;
        }
    }
    .process {
        .process-inner {
            .process-list {     
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 1.7rem;
                .process-item {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .process-shapes {
            img, svg {
                max-width: 23%;
                top: -16%;
            }
        }
    }
    .features {
        .features-list {
            grid-template-columns: repeat(1, 1fr);
            .features-item {
                > img {
                    max-width: 50%;
                    margin-top: -6%;
                }
            }
        }
        .features-shapes {
            img {
                &:last-of-type {
                    max-width: 23%;
                    top: 1%;
                }
            }
            svg {
                &:last-of-type {
                    max-width: 13%;
                    top: -3%;
                    left: 4%;
                }
            }
        }
    }
    .subscribe {
        .subscribe-inner {      
            padding: 0 1.2rem;
            max-height: unset;
            flex-direction: column;
            gap: 1rem;
            text-align: center;
            > img {
                max-width: 34%;
                margin-top: -11%;
            }
            .content-box {
                .form-group {
                    width: 100%;
                    .form-control {
                        height: 2.9rem !important;
                        font-size: 1.1rem !important;
                        padding-right: 7.3rem !important;
                    }
                    .btn-primary {                     
                        right: 2%;
                        padding: 0.5rem 1.5rem !important;
                    }
                }                
            }
            .subscribe-inner-shapes {
                img {
                    &:first-child {
                        top: -4%;
                        left: -17%;
                        max-width: 39%;
                    }
                    &:nth-child(2), &:nth-child(3) {
                        max-width: 10%;
                    }
                }
            }
        }
        .subscribe-shapes {
            img, svg {
                max-width: 16%;
                top: -5%;
            }
        }
    }
    .plans {
        .plans-list {     
            grid-template-columns: repeat(1, 1fr);
            .card {
                .plans-inner-shapes {                 
                    bottom: -10%;
                    right: -11%;                    
                    max-width: 63% !important;
                }
            }
        }
        .plans-shapes {
            top: 0.7%;
            img, svg {
                max-width: 48%;
            }
        }
    }
    .testimonials {
        .slick-dots {
            bottom: -4%;
        }
    }
    .call-to-action {
        padding: 10% 0%;
        .call-to-action-inner {
            gap: 1.3rem;
            padding: 1rem 0.9rem;
            max-height: unset;
            flex-direction: column;
            .content-box {
                order: 2;
                text-align: center;
                span {
                    justify-content: center;
                }
            }
            h3 {
                width: 100%;
                margin-bottom: 0.5rem;
            }
            > img {
                order: 1;
                max-width: 73%;
                margin-top: -11%;
                margin-bottom: 0;
                align-self: center;
            }
        }
        .call-to-action-shapes {
            img {
                &:last-child {
                    max-width: 19%;
                    top: -4%;
                }                
            }
            svg {
                &:last-of-type {
                    max-width: 24%;
                    top: -20%;
                    right: -6.8%;
                }
            }
        }
    }
    footer {
        margin: 1rem;
        margin-top: 0;
        .col-6 {
            width: 100% !important;
            &:not(:last-of-type) {
                margin-bottom: 0.8rem;
            }
            &:first-child {
                text-align: center;
            }
            &:nth-child(2), &:nth-child(3) {
                width: 50% !important;
            }
        }
        .logo {
            max-width: 57%;
        }
    }

    .inner-page-banner {
        min-height: 10rem !important;
        border-radius: 1.2rem !important;
    }
    .main-about-us {
        text-align: center;
        .img-box {
            margin-bottom: 1rem;
            img {
                max-width: 40% !important;
            }
            .shape-box {
               svg {
                    &:first-child {
                        max-width: 90%;
                    }
                    &:last-child {
                        max-width: 72%;
                    }
                }
            }
        }
    }
    .our-mission-inner {
        text-align: center;
    }
    .we-offer {
        .we-offer-list {       
            grid-template-columns: repeat(1, 1fr) !important;
        }
    }

    .main-features {
        .main-features-col {
            width: 100%;
            &:nth-child(2) {
                order: 1;
            }
            order: 2;
            margin-bottom: 1.1rem;
            &:last-of-type {
                margin-bottom: 0;
            }
            .mockup-img-box {
                display: block;
            }
            .main-features-data {
                text-align: center;
            }
        }
    }
    .mockup-img-box {
        img {
            max-width: 48% !important;
        }
        .shape-box {
            > div {
                &:last-child {
                    max-width: 92%;
                }
            }
        }
    }
    .main-benefits-second {
        .main-benefits-second-inner {
            ul {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
    .main-benefits-third {
        .main-benefits-third-inner {
            .form-group {
                width: 100% !important;
                .form-control {
                    height: 2.9rem !important;
                    font-size: 1.1rem !important;
                    padding-right: 7.3rem !important;
                }
                .btn-primary {                 
                    padding: 0.5rem 1.5rem !important;
                }
            }
            .content-box {
                h3 {
                    font-size: 1.1rem;
                    margin-bottom: 0.9rem;
                    line-height: 1.2;
                }
            }
        }
    }
    .main-benefits-last {
        svg {
            max-width: 100%;
        }
        ul {
            li {        
                text-align: center;
                padding: 0.9rem;
            }
        }
    }
     .terms-conditions-details {
     padding: 2rem 0 !important;
        .accordion-button { 
            font-size: 1.1rem !important;
        }
        .accordion-body {
        padding: 0.7rem !important;
        }
        .terms-conditions-details-list {
            ul {
                li {
                    font-size: 0.8rem !important;
                    margin-bottom: 0.6rem;
                    line-height: 1.4 !important;
                }
            }
        }
    }
}

@media screen and (max-width: 479px) {
    .main-banner {
        .banner-slide {
            .img-box {
                &::before {
                    width: 14rem;
                    height: 14rem;
                }
                &::after {
                    width: 11rem;
                    height: 11rem;
                }
            }
        }
    }
    .features {
        .features-list {
            .features-item {       
                flex-direction: column;
                gap: 1rem;
                padding: 1rem;
                .content-box {
                    text-align: center;
                    padding-right: 0;
                }
                > img {
                    max-width: 56%;
                }
                // &::before {                 
                //     clip-path: polygon(0% 0, 100% 0, 100% 100%, 0% 100%);
                //     top: 0;
                // }
            }
        }
    }
    .call-to-action {
        padding: 7% 0%;
    }
    footer {
        .copyright {
            flex-direction: column;
            gap: 0.5rem;
            justify-content: center;
        }
    }
    .inner-page-banner {
        h2 {
            font-size: 1.9rem !important;
        }
    }
    .main-about-us {
        .img-box {
            img {
                max-width: 48% !important;
            }
            .shape-box {
                svg {
                    &:first-child {
                        max-width: 100%;
                    }
                    &:last-child {
                        max-width: 84%;
                    }
                }
            }
        }
    }
    .main-benefits {
        svg {
            max-width: 100%;
        }
    }
    .terms-conditions-details {
        .accordion-button {
            font-size: 1rem !important;
        }   
    }

}


// about us page
.inner-page-banner {
    background-image: url('../assets/front-images/about-us-banner-img.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: calc(100vh - 33.7rem);
    border-radius: 2.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;       
    justify-content: center;
    position: relative;
    .shape-box {        
        svg {
            position: absolute;
            opacity: 0.4;
            animation: rotateblink 15s linear infinite;     
            transform-origin: center;
            transform-box: fill-box;  
            height: auto;
            &:first-child {
                top: -9%;
                left: -1%;
                transform: rotate(287deg);
            }
            &:last-child {
                bottom: -5%;
                left: 25%;            
            }
        }
    }
    h2 {
        color: $white;        
        font-size: 3rem;        
        font-weight: 600;
        line-height: normal;        
    }
    .breadcrumb {
        margin: 0;
        display: flex;
        align-items: center;
        li, a {
            font-size: 1.3rem;
            color: $white;
            &::before {
                color: $white;
            }
        }
    }
}


.main-about-us {
    svg {
        height: 100%;
    }
    .row {
        align-items: center;
    }
    .img-box {
        position: relative;       
        img {
            display: block;
            margin: 0 auto;
            position: relative;
            z-index: 1;
            max-width: 38%;
        }
        .shape-box {
            svg {
                position: absolute;     
                margin: 0 auto;         
                max-width: max-content;          
                &:first-child {
                    top: 63%;
                    transform: translateY(-50%);                    
                    left: 0;
                    right: 0;
                    z-index: 9;
                }
                &:last-child {
                    top: 50%;
                    transform: translateY(-50%);       
                    left: 0;
                    right: 0;
                    z-index: 0;
                }
            }
        }
        svg {
            max-width: 100%;
        }
        #img-notification, #img-notification2 {
            animation: blinker 2.5s linear infinite;
        }
        #roundline-svg {
            animation: rotate 55s linear infinite;     
            transform-origin: center;
            transform-box: fill-box;  
        }       
    }
}
.mockup-img-box {
    position: relative;       
    img {
        display: block;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        max-width: 48%;
    }
    .shape-box {
        > div {
            position: absolute;     
            margin: 0 auto;         
            max-width: max-content;          
            &:first-child {
                top: 63%;
                transform: translateY(-50%);                    
                left: 0;
                right: 0;
                z-index: 9;
            }
            &:last-child {
                top: 50%;
                transform: translateY(-50%);       
                left: 0;
                right: 0;
                z-index: 0;
            }
        }
    }
    svg {
        max-width: 100%;
        height: 100%;
    }
    #img-notification, #img-notification2 {
        animation: blinker 2.5s linear infinite;
    }
    #roundline-svg {
        animation: rotate 55s linear infinite;     
        transform-origin: center;
        transform-box: fill-box;  
    }       
}

.our-mission-inner{
    background-color: rgba($front-secondary, 0.08);
    border-radius: 1.8rem;
    padding: 3.1rem 3rem;
    svg {
        max-width: 100%;
        height: 100%;
    }
    #missionhand {
        animation: hand 1.5s linear infinite;
        transform-origin: left;
        transform-box: fill-box;
    }   
    #missionhand2 {
        animation: hand2 2s linear infinite;
        transform-origin: left;
        transform-box: fill-box;
    }   
    #missionhand3 {
        animation: hand 2s linear infinite;
        transform-origin: right;
        transform-box: fill-box;
    }    
    #missionhand4 {
        animation: hand2 2s linear infinite;
        transform-origin: left;
        transform-box: fill-box;
    } 
    #rocket {
        animation: shake 1.5s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
}
.we-offer {    
    .we-offer-list {
        max-width: 70%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 3rem;
        margin-top: 3rem;
        .we-offer-detail-item {
            padding: 1.5rem;
            padding-top: 0;
            border-radius: 1.3rem;
            &:first-child {
                background-color: lighten($front-secondary, 46%);                
            }            
            &:nth-child(2) {
                background-color: lighten($front-pink, 44%);
            }            
            &:nth-child(3) {
                background-color: lighten($front-orange, 45%);
            }
            &:last-child {
                background-color: lighten($front-neon, 44%);
            }
            span {
                color: transparent;
                font-size: 4.5rem;                                
                line-height: normal;
                display: block;
                font-weight: 700;
                -webkit-background-clip: text;
                -webkit-text-stroke-width: 2px;
                -webkit-text-stroke-color: $primary;
                margin-top: -1.9rem;
                line-height: 1.1;
            }
            h4 {
                font-size: 2rem;                
                font-weight: 500;
                line-height: normal;
                margin-bottom: 0.4rem;
            }
        }
    }
}
}
// features page
.features-baneer {
    .inner-page-banner {
        background-image: url('../assets/front-images/features-banner-img.png');
    }
}

.main-features {
    .row {
        margin-top: 3rem;
    }
    .main-features-data {
        padding: 3rem;                
        max-width: 78%;
        margin-bottom: 3rem;
        border-radius: 1rem 9rem 1rem 9rem;        
        h4 {
            font-size: 1.8rem;
            margin-bottom: 1rem;
            line-height: 2.3rem;
        }
    }
    .mockup-img-box {
        margin-bottom: 3rem;
    }
    .main-features-col {
        &:first-child {
            .main-features-data {           
                margin-left: auto;    
                &:first-child {
                    background-color: lighten($primary, 36%);                
                } 
                &:last-child {
                    background-color: lighten($front-orange, 45%);     
                    margin-bottom: 0;                          
                }       
            }
        }
        &:nth-child(2) {
            margin-top: 5rem;
            .main-features-data {               
                background-color: lighten($front-pink, 44%);    
                margin-bottom: 0;       
                margin: 0 auto;                   
            }
        }
        &:last-child {
            .main-features-data {        
                margin-right: auto;       
                &:first-child {
                    background-color: lighten($front-neon, 44%);                    
                }
                &:last-child {
                    background-color: lighten($front-secondary, 46%);     
                    margin-bottom: 0;                          
                }   
            }
        }
    }   
}

.main-benefits {
    svg {
        max-width: 100%;
        height: 100%;
    }
    .row {
        margin-top: 3rem;
    }
    ul {
        li {
            padding: 2rem 1.3rem;            
            margin-bottom: 1rem;
            border-radius: 0 1rem 1rem 0;
            &:first-child {
                background-color: lighten($front-pink, 46%);   
                border-left: 6px solid lighten($front-pink, 30%);
            }
            &:nth-child(2) {
                background-color: lighten($front-secondary, 46%); 
                border-left: 6px solid lighten($front-secondary, 30%);
            }
            &:nth-child(3) {
                background-color: lighten($primary, 36%);   
                border-left: 6px solid lighten($primary, 20%);
            }
            &:last-child {
                background-color: lighten($front-orange, 45%);    
                border-left: 6px solid lighten($front-orange, 30%);
                margin-bottom: 0;
            }
            h5 {
                font-size: 1.3rem;
                margin-bottom: 0.7rem;
                letter-spacing: 0.02rem;
            }
        }
    }
    #benefit-handimg {
        animation: hand 3s linear infinite;
        transform-origin: right;
        transform-box: fill-box;
    }
    #benefit-handimg2 {
        animation: hand2 2s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
    #face  {
        animation: hair 5.1s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
    #hair {
        animation: hair 5.1s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
    #screen {
        animation: blinker 1.5s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
    #small-shapes, #small-shapes2, #small-shapes3{
        animation: rotate 5s linear infinite;
        transform-origin: center;
        transform-box: fill-box;
    }    
}

.main-benefits-second {
    .main-benefits-second-inner {
        background-color: rgba($front-secondary, 0.08);
        border-radius: 1.8rem;
        padding: 3.1rem 2.7rem;        
        ul {
            display: grid;
            grid-template-columns: repeat(4,1fr);
            grid-gap: 2rem;
            li {                
                position: relative;
                .animated {
                    padding-top: 1rem;
                }
                span {
                    font-size: 3.2rem;
                    font-weight: 700;
                    color: $gray-400;
                    // position: absolute;
                    // top: -3%;
                    // left: -2%;
                    // z-index: -1 !important;
                }
                h5 {
                    font-size: 1.4rem;
                    margin-bottom: 0.5rem;
                    letter-spacing: 0.02rem;
                }
            }            
        }
    }    
}
.main-benefits-third {
    position: relative;    
    .main-benefits-third-inner {
        background: linear-gradient(323deg, #6E5FCC 0%, #898DEA 31.03%, #8A8FFF 79.17%, #C5DCFF 100%);
        border-radius: 1.8rem;          
        padding: 3rem 6rem;     
        text-align: center;
        position: relative;           
        .content-box {
            h3 {
                color: $white;
                font-size: 2.6rem;         
                font-weight: 400;                       
                letter-spacing: 0.05rem;
                margin-bottom: 2rem;
                line-height: 1.4;
            }
            .form-group {
                position: relative;
                width: 50%;
                margin: 0 auto;
                .form-control {                    
                    border-radius: 3rem;
                    padding-right: 13rem;
                    padding-left: 1.4rem;
                    background-color: $white !important;
                    color: $gray-700;
                    height: 3.8rem;
                    align-items: center;
                    border-color: transparent;
                    font-size: 1.4rem;
                    letter-spacing: 0.02rem;
                    &:focus, &:hover {
                        border-color: $primary;
                    }
                    &::placeholder {
                        color: $gray-500;
                    }
                }
                .btn-primary {
                    border-radius: 3rem;
                    padding: 0.5rem 3rem;
                    position: absolute;
                    right: 1%;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .main-benefits-inner-shapes {
            img {
                position: absolute;                
                opacity: 0.5;
                &:first-child {                                        
                    right: -3%;                    
                    bottom: 0%;                    
                }
                &:nth-child(2) {
                    top: 2%;                    
                    left: 44%;
                }
                &:nth-child(3) {
                    bottom: 22%;
                    left: 19%;
                }
            }
        }
    }    
}

.main-benefits-last {
    li {
        padding: 1.2rem;
        background-color: lighten($primary, 36%);  
        border-radius: 1rem;
        max-width: 73%;
        margin: 0 auto;
        margin-bottom: 2rem;
        &:nth-child(2) {
            margin-left: auto;
            margin-right: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        h5 {
            font-size: 1.4rem;
            margin-bottom: 0.7rem;
            letter-spacing: 0.02rem;
        }
        p {
            color: #7c7c7c;
        }
    }
    svg {
        max-width: 100%;
        height: 100%;
    }
    #benefits-hair2 {
        animation: hair 5.1s linear infinite;
        transform-origin: right;
        transform-box: fill-box;
    }
    #circle-benefits, #circle-benefits2, #circle-benefits3 {
        animation: rotate 18s linear infinite;
        transform-origin: center;
        transform-box: fill-box;
    }
    #mic-hand {
        animation: hand 3s linear infinite;
        transform-origin: left;
        transform-box: fill-box;
    }
    #hand-phone {
        animation: hand 3s linear infinite;
        transform-origin: right;
        transform-box: fill-box;        
    }
    #screen2 {
        animation: blinker 1.4s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
    #video {
        animation: shake 3s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
    #like-video {
        animation: girlplay 1.5s linear infinite;
        transform-origin: bottom;
        transform-box: fill-box;
    }
}

.term-conditions-baneer {
    .inner-page-banner {
        background-image: url('../assets/front-images/privacy-policy-banner-img.png');
    }
}

.terms-conditions-details {
    padding: 3rem 0 !important;
    h4 {
        font-size: 2rem;
        color: $primary;
        margin-bottom: 1rem;
    }
    .terms-conditions-details-list {        
        margin-top: 1rem;
        h6 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
            color: lighten($front-light-pink, 20%);
        }
        ul {
            // margin: 0 2rem;
            li {
                position: relative;
                padding-left: 1rem;
                margin-bottom: 0.8rem;
                font-size: 1.1rem;
                letter-spacing: 0.1rem;
                line-height: 1.5;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &::before {
                    content: "";
                    width: 0.37rem;
                    height: 0.37rem;
                    border-radius: 50%;
                    display: block;
                    background-color: $gray-600;
                    position: absolute;
                    top: 0.7rem;
                    left: 0;
                }
            }
        }
    }
    .accordion-item {
        margin-bottom: 1rem;
        border: none;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .accordion-button {
        padding: 0.6rem 0.7rem;        
        background-color: lighten($front-light-pink, 45%);
        color: lighten($front-light-pink, 20%);
        border-radius: 0.2rem;
        font-size: 1.3rem;
        text-align: left;
        width: 100%;
        border: 1px solid lighten($front-light-pink, 20%);
        margin: 0 !important;
        font-weight: 500;
        box-shadow: none;
        &::after {
            filter: brightness(0) saturate(100%) invert(61%) sepia(14%) saturate(7223%) hue-rotate(302deg) brightness(102%) contrast(101%);
        }
    }
    .collapse {
        border: 1px solid lighten($front-light-pink, 20%) !important;
        border-top: none !important;
        border-radius: 0 0 0.5rem 0.5rem !important;                          
    }
}





// keyframes
@keyframes shake {
    0% {
    transform: translate(2px, 1px) rotate(0deg);
   }
    10% {
    transform: translate(-1px, -2px) rotate(-1deg);
   }
    20% {
    transform: translate(-3px, 0px) rotate(1deg);
   }
    30% {
    transform: translate(0px, 2px) rotate(0deg);
   }
    40% {
    transform: translate(1px, -1px) rotate(1deg);
   }
    50% {
    transform: translate(-1px, 2px) rotate(-1deg);
   }
    60% {
    transform: translate(-3px, 1px) rotate(0deg);
   }
    70% {
    transform: translate(2px, 1px) rotate(-1deg);
   }
    80% {
    transform: translate(-1px, -1px) rotate(1deg);
   }
    90% {
    transform: translate(2px, 2px) rotate(0deg);
   }
    100% {
    transform: translate(1px, -2px) rotate(-1deg);
   }
   }
@keyframes wave {
	0% {
		transform: rotateZ(2deg) translateZ(-1px);
	}
	25%{
		transform: rotateZ(-1deg) translateZ(-1px);
	}
	50% {
		transform: rotateZ(2deg) translateZ(-1px);
	}
	75% {
		transform: rotateZ(-2deg) translateZ(-1px);
	}
	100% {
		transform: rotateZ(2deg) translateZ(-1px);
	}
}
@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

@keyframes rotateblink {
    from {
        transform: rotateZ(360deg) scale(0.8);
        opacity: 0.1;
    }
    to {
        transform: rotateZ(0deg) scale(1);
        opacity: 0.8;
    }
}

@keyframes rotate {
    from {
        transform: rotateZ(360deg);
    }
    to {
        transform: rotateZ(0deg);
    }
}
@keyframes rotateslow {
    from {
        transform: rotateZ(360deg);
    }
    to {
        transform: rotateZ(0deg);
    }
}
@keyframes boat{
	0%{
		transform: rotate(-3deg) translateX(30px);
	}
	50%{
		transform: rotate(3deg) translateX(-10px);
	}   
	100%{
		transform: rotate(-3deg) translateX(30px);
	}
}
@keyframes wood{
	0%{
		transform: rotate(-3deg);
	}
	50%{
		transform: rotate(3deg);
	}   
	100%{
		transform: rotate(-3deg);
	}
}
@keyframes girlplay{
	0%{
		transform: rotate(-5deg);
	}
	50%{
		transform: rotate(5deg);
	}   
	100%{
		transform: rotate(-5deg);
	}
}

@keyframes paper-plane-soaring {
    0% {                
        transform: rotate(0deg);
      }
      40% {                
        transform: rotate(2deg);
      }
      50% {                
        transform: rotate(2deg);
      }
      60% {                
        transform: rotate(-5deg);
      }
      70% {                
        transform: rotate(-5deg);
      }
      100% {        
        transform: rotate(0deg);
      }
    
}

@keyframes hand {
    0%, 100% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(-3deg);
    }
}
@keyframes hand2 {
    0%, 100% {
        transform: rotate(4deg);
    }
    50% {
        transform: rotate(-3deg);
    }
}
@keyframes hair {
    0%, 100% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(6deg);
    }
}
@keyframes hair2 {
    0%, 100% {
        transform: rotate(-3deg);
    }
    50% {
        transform: rotate(3deg);
    }
}
@keyframes balloonmove{
    0% {right:0px;}
    20%{right:50px; transform:rotate(3deg);}
    40%{right:100px;}
    60%{right:50px; transform:rotate(-30deg);}
    80%{right:70px; transform:rotate(3deg);}
    100%{right:0px; transform:rotate(0deg);}
  }

  @keyframes pulse-process {
    0% {
        box-shadow: 0 0 0 0px rgba($front-yellow, 0.7);
      }
      100% {
        box-shadow: 0 0 0 0.9rem rgba($front-yellow, 0);
      }    
  }
  @keyframes blinker {
    50% {
      opacity: 0.5;
    }
  }